import React from 'react';
import {Box, CardMedia, Container, Typography} from "@mui/material";
import img from "../../images/oh-no.gif";
import Link from "@mui/material/Link";
import {useNavigate} from "react-router-dom";

const ErrorComponent: React.FC = (props) => {
    let navigate = useNavigate();
    return (
        <Container maxWidth="sm">
            <Box sx={{borderRadius: 2, m: 1}}>
                <CardMedia
                    sx={{borderRadius: 2, backgroundColor: 'transparent'}}
                    component="img"
                    //height="170"
                    image={img}
                    alt="login"
                />
            </Box>
            <Typography sx={{color: 'darkgoldenrod'}} gutterBottom component="h2">
                Ups... something goes wrong
            </Typography>

            <Link
                component="button"
                variant="body2"
                onClick={() => {
                    navigate(-1);
                }}
            >
                Go back
            </Link>


        </Container>
    );
}

export default ErrorComponent;