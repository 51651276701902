import styled from "@emotion/styled";
import {Button, Container} from "@mui/material";

export const GreenButton = styled(Button)({
    display: 'flex',
    mb: 2,
    mr: 2,
    color: 'black',
    '&:hover': {
        backgroundColor: '#e3e6db',
    }
}) as typeof Button;


export const OverloadContainer = styled(Container)({
    mb: 5,
    borderRadius: 15,
    backgroundColor: 'white',
    position: 'relative',
    maxWidth:'96%',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
    '&:hover': {
        boxShadow: '0px 7px 14px rgba(0, 0, 0, 0.3)'
    }
}) as typeof Container;
