import React, {useState} from "react";
import {AppBar, Box, Button, Container, Grid, IconButton, Typography, useMediaQuery} from "@mui/material";
import logo from "../../images/logo-transparent.png";
import {useNavigate} from "react-router-dom";
import {GreenButton} from "./StyledComponents";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import styled from "@emotion/styled";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import SellIcon from '@mui/icons-material/Sell';
import ContactPageIcon from '@mui/icons-material/ContactPage';


type Anchor = 'top' | 'left' | 'bottom' | 'right';

const HeaderComponent: React.FC = () => {
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [selectedIndex, setSelectedIndex] = useState(1);

    let navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery('(min-width:1224px)');

    function createMenuButtons() {
        return (
            <Box sx={{display: 'flex', mt:.4, mb: .4}}>
                <Grid container justifyContent="flex-start">
                    <Button sx={{backgroundColor: 'rgba(250,255,255,.08)', height: 40,ml: isDesktopOrLaptop ? 10 : 1}} onClick={() => navigate('/')}>
                        <img style={{height: 40}} src={logo} alt="banner"/>
                    </Button>
                </Grid>

                <Grid container justifyContent="center" alignContent='center'>
                    {!isDesktopOrLaptop && <a href="tel:0747052042" style={{textDecoration: 'none'}}>
                        <BoxLink>
                            <PhoneIcon sx={{color: '#03045e', pr: 1}}/>
                            <Typography style={{fontSize: 16, color: '#03045e'}}>
                                0747052042
                            </Typography>
                        </BoxLink>
                    </a>}
                </Grid>


                <Grid container justifyContent='flex-end' alignContent='center'>
                    <Box sx={{display: 'flex', mr: isDesktopOrLaptop ? 20 : 1}}>
                        {!isDesktopOrLaptop && navigationButtonsMobile()}
                        {isDesktopOrLaptop && navigationButtonsDesktop()}

                    </Box>
                </Grid>
            </Box>
        );
    }

    function navigationButtonsDesktop() {
        return (
            <React.Fragment>
                <GreenButton onClick={() => navigate('/')}>
                    <Typography style={{userSelect: 'none', fontSize: 18}}>
                        HOME
                    </Typography>
                </GreenButton>


                <GreenButton>
                    <Typography style={{userSelect: 'none',fontSize: 18}} onClick={() => navigate('/despre-noi')}>
                        DESPRE NOI
                    </Typography>
                </GreenButton>


                <GreenButton onClick={() => navigate('/contact')}>
                    <Typography style={{userSelect: 'none',fontSize: 18}}>
                        CONTACT
                    </Typography>
                </GreenButton>
            </React.Fragment>
        )
    }

    const BoxLink = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
    }) as typeof Box;


    function createContactBanner() {
        return <Container disableGutters maxWidth={false} sx={{
            backgroundColor: 'rgba(255,121,0,.8)',
            display: 'flex',
            justifyContent: 'space-between',
            pr: 20,
            pl: 20,
            pt: .5,
            pb: .5,
            alignItem: 'center'
        }}>
            <BoxLink>
                <HomeIcon sx={{color: 'white', pr: 1}}/>
                <Typography style={{userSelect: 'none',fontSize: 16, color: 'white'}}>
                    Selimber, RO
                </Typography>
            </BoxLink>


            <a href="tel:0747052042" style={{textDecoration: 'none'}}>
                <BoxLink>
                    <PhoneIcon sx={{color: 'white', pr: 1}}/>
                    <Typography style={{userSelect: 'none',fontSize: 16, color: 'white'}}>
                        0747052042
                    </Typography>
                </BoxLink>
            </a>

            <a href="mailto:office@strong-logistic.ro" style={{textDecoration: 'none'}}>
                <BoxLink>
                    <EmailIcon sx={{color: 'white', pr: 1}}/>
                    <Typography style={{userSelect: 'none',fontSize: 16, color: 'white'}}>
                        office@strong-logistic.ro
                    </Typography>
                </BoxLink>
            </a>
        </Container>;
    }

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
    };

    function navigationButtonsMobile() {
        return (
            <React.Fragment key={'top'}>

                <IconButton
                    size={isDesktopOrLaptop ? "large" : "small"}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={toggleDrawer('top', true)}
                    color="inherit"
                >
                    <MenuIcon sx={{color: 'black'}}/>
                </IconButton>

                <Box
                    role="presentation"
                    onClick={toggleDrawer('top', false)}
                    onKeyDown={toggleDrawer('top', false)}
                >
                    <Drawer
                        anchor={'top'}
                        open={state['top']}
                        onClose={toggleDrawer('top', false)}
                    >

                        <List component="nav" aria-label="main mailbox folders">
                            <ListItemButton
                                sx={{
                                    width: '100%',
                                    backgroundColor: 'background.paper',
                                    height: 50
                                }}
                                selected={selectedIndex === 0}
                                onClick={(event) => {
                                    handleListItemClick(event, 0);
                                    navigate("/");
                                }}
                            >
                                <ListItemIcon>
                                    <HomeIcon sx={{color: 'black', ml: 2, mr: 4}}/>
                                </ListItemIcon>
                                <ListItemText primary="Home"/>
                            </ListItemButton>

                            <ListItemButton
                                sx={{
                                    width: '100%',
                                    backgroundColor: 'background.paper',
                                    height: 50
                                }}
                                selected={selectedIndex === 1}
                                onClick={(event) => {
                                    handleListItemClick(event, 1);
                                    navigate("/despre-noi")
                                }}
                            >
                                <ListItemIcon>
                                    <SellIcon sx={{color: 'black', ml: 2, mr: 4}}/>
                                </ListItemIcon>
                                <ListItemText primary="Despre noi"/>
                            </ListItemButton>


                            <ListItemButton
                                sx={{
                                    width: '100%',
                                    backgroundColor: 'background.paper',
                                    height: 50
                                }}
                                selected={selectedIndex === 2}
                                onClick={(event) => {
                                    handleListItemClick(event, 2);
                                    navigate("/contact")
                                }}
                            >
                                <ListItemIcon>
                                    <ContactPageIcon sx={{color: 'black', ml: 2, mr: 4}}/>
                                </ListItemIcon>
                                <ListItemText primary="Contact"/>
                            </ListItemButton>

                        </List>
                    </Drawer>
                </Box>
            </React.Fragment>
        )
    }

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        setSelectedIndex(index);
        toggleDrawer('top', false);
    };


    return (
        <>
            <AppBar position="fixed" sx={{backgroundColor: 'transparent'}}>
                {isDesktopOrLaptop && createContactBanner()}

                <Container disableGutters maxWidth={false} sx={{backgroundColor: 'rgba(250,255,255,.5)'}}>
                    {createMenuButtons()}
                </Container>

            </AppBar>


        </>
    )
}

export default HeaderComponent;