import React from "react";
import {Box, CardMedia, Typography} from "@mui/material";
import contactUs from "../../images/termeni.jpg";
import {OverloadContainer} from "../elements/StyledComponents";
import {useMediaQuery} from "react-responsive";
import styled from "@emotion/styled";
import {Helmet} from "react-helmet-async";

const TermsPage: React.FC = () => {
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});

    const TermsTypography = styled(Typography)({
        userSelect: 'none',
        fontFamily: 'open-sans-regular',
        textAlign: 'left',
        marginBottom: 20
    }) as typeof Typography;

    function Options(props: {
        messageTitle: any;
        step: string;
        message: string;
        secondMessage?: string | undefined
    }) {
        return (
            <Box sx={{display: 'flex', mb: 3}}>
                <Typography
                    style={{
                        fontFamily: 'open-sans-bold',
                        color: 'green',
                        marginLeft: 10,
                        marginRight: 10
                    }}>  {props.step}</Typography>
                <Box>
                    <Typography
                        style={{
                            userSelect: 'none',
                            textAlign: 'left',
                            fontFamily: 'open-sans-bold',
                            minWidth: '270px',
                        }}>{props.messageTitle}</Typography>
                    <Typography
                        style={{
                            userSelect: 'none',
                            textAlign: 'left',
                            fontFamily: 'open-sans-regular',
                            marginLeft: 10
                        }}>{props.message}</Typography>
                    <Typography
                        style={{
                            userSelect: 'none',
                            textAlign: 'left',
                            fontFamily: 'open-sans-regular',
                            marginLeft: 10
                        }}>{props.secondMessage}</Typography>
                </Box>

            </Box>
        )
    }

    return (
        <div>
            <Helmet>
                <title>Termeni si conditii</title>
                <meta name='description' content='Strong logistic SRL contact.'/>
                <link rel="canonical" href="https://strong-logistic.ro/termeni-conditii" />
            </Helmet>

            <CardMedia
                sx={{
                    borderRadius: 0,
                    backgroundColor: 'transparent',
                }}
                component="img"
                height={isDesktopOrLaptop ? 500 : 250}
                image={contactUs}
                alt="banner"
            />

            <OverloadContainer
                maxWidth={false}
                sx={{
                    mt: isDesktopOrLaptop ? -15 : -5,
                    boxShadow: 10,
                    mb: 2,
                    pb:2
                }}>

                <Typography
                    style={{
                        userSelect: 'none',
                        fontFamily: 'open-sans-bold',
                        fontSize: 25,
                        color: "#0e114c",
                        marginBottom: 20,
                        paddingTop: 30
                    }}>
                    TERMENI SI CONDITII
                </Typography>
                <TermsTypography>
                    Termenii si conditiile pentru website-ul strong-logistic sunt o serie de reguli si regulamente care
                    guverneaza utilizarea site-ului si serviciile oferite prin intermediul acestuia. Acestea sunt create
                    pentru a proteja atat interesele utilizatorilor, cat si ale companiei, dupa cum urmeaza:
                </TermsTypography>


                <Options
                    step={"1."}
                    messageTitle={"Definirea serviciilor:"}
                    message={" - Strong Logistic SRL ofera servicii de transport si logistica. Detaliile exacte ale acestor servicii sunt descrise pe site."}/>

                <Options
                    step={"2."}
                    messageTitle={"Termeni de utilizare:"}
                    message={" - prin utilizarea site-ului si a serviciilor oferite, utilizatorul accepta sa respecte termenii si conditiile prezentate."}/>

                <Options
                    step={"3."}
                    messageTitle={"Responsabilitatea utilizatorului:"}
                    message={" - utilizatorul este responsabil pentru furnizarea informatiilor corecte si actuale necesare pentru utilizarea serviciilor oferite de Strong Logistic SRL."}/>

                <Options
                    step={"4."}
                    messageTitle={"Proprietatea intelectuala:"}
                    message={" - continutul site-ului, inclusiv logo-urile, imaginile, textul si alte materiale, sunt protejate de drepturi de autor si sunt proprietatea exclusiva a Strong Logistic SRL, si nu pot fi prelucrate, preluate sau modificate fara acordul prealabil al proprietarului"}/>

                <Options
                    step={"5."}
                    messageTitle={"Confidentialitate:"}
                    message={" - Strong Logistic SRL se angajeaza sa respecte confidentialitatea informatiilor personale ale utilizatorilor si sa le utilizeze numai in scopurile descrise in politica de confidentialitate a site-ului."}
                    secondMessage={" - website - ul strong-logistic.ro nu stocheaza niciun fel de informatii cu caracter personal."}/>

                <Options
                    step={"6."}
                    messageTitle={"Modificari ale termenilor si conditiilori:"}
                    message={" - Strong Logistic SRL isi rezerva dreptul de a modifica termenii si conditiile in orice moment, fara o notificare prealabila. Utilizatorul trebuie sa verifice periodic aceste termeni si conditii pentru orice modificar."}/>

                <Options
                    step={"7."}
                    messageTitle={"Limitarea raspunderii:"}
                    message={" - Strong Logistic SRL nu poate fi facuta responsabila pentru nicio pierdere sau daune directe sau indirecte cauzate de utilizarea site-ului sau a serviciilor oferite prin intermediul acestuia."}
                    />
            </OverloadContainer>
        </div>
    )
}

export default TermsPage;