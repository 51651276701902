import React from "react";
import {Box, CardMedia, Grid, Typography} from "@mui/material";
import contactUs from "../../images/contact-us.jpg";
import {useMediaQuery} from "react-responsive";
import {OverloadContainer} from "../elements/StyledComponents";
import logo from "../../images/logo-transparent.png";
import styled from "@emotion/styled";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import {Helmet} from "react-helmet-async";

const ContactPage: React.FC = () => {
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});

    const ContactBox = styled(Box)({
        backgroundColor: 'rgba(250,255,255,.05)',
        elevation: 30,
        height: isDesktopOrLaptop ? 260 : 200,
        width: 450,
        borderRadius: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 20,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            boxShadow: '0px 7px 14px rgba(0, 0, 0, 0.3)'
        }
    }) as typeof Box;

    return (
        <div>
            <Helmet>
                <title>Contact</title>
                <meta name='description' content='Strong logistic SRL terms and conditions.'/>
                <link rel="canonical" href="https://strong-logistic.ro/contact" />
            </Helmet>

            <CardMedia
                sx={{
                    borderRadius: 0,
                    backgroundColor: 'transparent',
                }}
                component="img"
                height={isDesktopOrLaptop ? 500 : 250}
                image={contactUs}
                alt="banner"
            />

            <OverloadContainer
                maxWidth={false}
                sx={{
                    mt: isDesktopOrLaptop ? -15 : -5,
                    boxShadow: 10,
                    mb: 2
                }}>

                <Typography
                    style={{
                        fontFamily: 'open-sans-bold',
                        fontSize: 25,
                        color: "black",
                        marginBottom: 20,
                        paddingTop: 30
                    }}>
                    CONTACT
                </Typography>

                <Typography style={{fontSize: 18, color: '#495057'}}>
                    Pentru a ne contacta va rugam sa folositi una din urmatorele metode:
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        mt: 3,
                        pb: 5,
                        backgroundColor: 'transparent',
                        width: '100%',
                        flexDirection: isDesktopOrLaptop ? 'row' : 'column',
                        justifyContent: 'space-around'
                    }}>

                    <Grid container justifyContent={isDesktopOrLaptop ? 'flex-end' : 'center'} alignContent='center'
                          sx={{p: 1, flex: 2}}>
                        <ContactBox sx={{boxShadow: 10}}>
                            <PhoneIphoneIcon style={{fontSize: 100, color: '#495057', marginBottom: 10}}/>
                            <Typography style={{
                                fontSize: 16,
                                color: 'rgba(255,121,0,.8)',
                                marginBottom: 2,
                                fontFamily: "open-sans-bold"
                            }}>
                                Telefon / WhatsApp
                            </Typography>
                            <a href="tel:0747052042" style={{textDecoration: 'none'}}>
                                <Typography style={{fontSize: 20, color: 'black'}}>
                                    +40747052042
                                </Typography>
                            </a>
                        </ContactBox>
                    </Grid>

                    <Grid container justifyContent='center' alignContent='center' sx={{p: 1, flex: 2}}>
                        <ContactBox sx={{boxShadow: 10}}>
                            <LocationOnIcon style={{fontSize: 100, color: '#495057', marginBottom: 10}}/>
                            <Typography style={{
                                fontSize: 16,
                                color: 'rgba(255,121,0,.8)',
                                marginBottom: 2,
                                fontFamily: "open-sans-bold"
                            }}>
                                Adresa
                            </Typography>
                            <Typography style={{fontSize: 18, color: 'black', paddingLeft: 10}}>
                                Str. Regele Ferdinand 5 bl 2, ap 23
                            </Typography>
                            <Typography style={{fontSize: 18, color: 'black', paddingLeft: 10}}>
                                RO - 557260 Selimber, Sibiu
                            </Typography>

                        </ContactBox>
                    </Grid>

                    <Grid container justifyContent={isDesktopOrLaptop ? 'flex-start' : 'center'} alignContent='center'
                          sx={{p: 1, flex: 2}}>
                        <ContactBox sx={{boxShadow: 10}}>
                            <EmailIcon style={{fontSize: 100, color: '#495057', marginBottom: 10}}/>
                            <Typography style={{
                                fontSize: 16,
                                color: 'rgba(255,121,0,.8)',
                                marginBottom: 2,
                                fontFamily: "open-sans-bold"
                            }}>
                                E-mail
                            </Typography>
                            <a href="mailto:office@strong-logistic.ro" style={{textDecoration: 'none'}}>
                                <Typography style={{fontSize: 20, color: 'black', paddingLeft: 10}}>
                                    office@strong-logistic.ro
                                </Typography>
                            </a>
                        </ContactBox>
                    </Grid>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: isDesktopOrLaptop ? 'row' : 'column',
                    justifyContent: 'center',
                    padding: 5
                }}>
                    <Box sx={{display: 'flex', flexDirection: 'column', paddingRight: isDesktopOrLaptop ? 20 : 0}}>
                        <Typography style={{fontSize: 20, color: 'black'}}>
                            Detalii Firma
                        </Typography>
                        <Typography style={{fontSize: 25, color: '#0e114c', fontFamily: "open-sans-bold"}}>
                            STRONG LOGISTIC SRL
                        </Typography>
                        <img style={{height: 100, alignSelf: 'center'}} src={logo} alt="banner"/>
                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'column', pt: 4, alignItems: 'flex-start'}}>
                        <Typography
                            style={{fontSize: 20, color: '#495057', fontFamily: "open-sans-regular"}}>
                            Cod Unic de Înregistrare: 36489588
                        </Typography>
                        <Typography style={{fontSize: 20, color: '#495057'}}>
                            Nr. Înmatriculare: J32/1143/2016
                        </Typography>
                        <Typography style={{fontSize: 20, color: '#495057'}}>
                            EUID: ROONRC.J32/1143/2016
                        </Typography>
                    </Box>
                </Box>

            </OverloadContainer>
        </div>
    )
}

export default ContactPage;