import React from 'react';
import './App.css';
import HeaderComponent from "./components/elements/HeaderComponent";
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import ErrorComponent from "./components/pages/ErrorComponent";
import HomeComponent from "./components/pages/HomeComponent";
import {Box, Container} from "@mui/material";
import FooterComponent from "./components/elements/FooterComponent";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import ContactPage from "./components/pages/ContactPage";
import TermsPage from "./components/pages/TermsPage";
import AboutUsPage from "./components/pages/AboutUsPage";
import {HelmetProvider} from 'react-helmet-async';


function App() {

    const Layout = () => (
        <>
            <HeaderComponent/>
            <OverlayScrollbarsComponent
                options={{
                    showNativeOverlaidScrollbars: false,
                    scrollbars: {visibility: 'auto'},
                }}
            >
                <Outlet/>
            </OverlayScrollbarsComponent>
            <FooterComponent/>
        </>
    );

    const routers = createBrowserRouter([
        {
            element: <Layout/>,
            errorElement: <ErrorComponent/>,
            children: [
                {
                    path: "/",
                    element: <HomeComponent/>,
                },
                {
                    path: "/contact",
                    element: <ContactPage/>,
                },
                {
                    path: "/termeni-conditii",
                    element: <TermsPage/>,
                },
                {
                    path: "/despre-noi",
                    element: <AboutUsPage/>,
                }
            ]
        }
    ]);


    return (
        <HelmetProvider>
            <Container
                className="App"
                disableGutters
                maxWidth={false}
                sx={{/*backgroundColor: '#17202A'*/
                    '& .theme-header': {
                        backgroundColor: '#000000',
                        color: '#FFFFFF',
                        fontSize: 12
                    },
                    '& .home-team': {
                        backgroundColor: '#008000',
                    },
                    '& .away-team': {
                        backgroundColor: '#008080',
                    },
                    '& .theme-goals': {
                        backgroundColor: '#C0C0C0',
                    },
                    '& .theme-stat': {
                        backgroundColor: '#ADD8E6',
                    },
                    minHeight: window.innerHeight,
                }}>
                <Box>
                    <RouterProvider router={routers}/>
                </Box>

            </Container>
        </HelmetProvider>
    );
}

export default App;
