import React from "react";
import contactUs from "../../images/about-us.jpg";
import {CardMedia, Typography} from "@mui/material";
import {useMediaQuery} from "react-responsive";
import {OverloadContainer} from "../elements/StyledComponents";
import styled from "@emotion/styled";
import logo from "../../images/logo-transparent.png";
import {Helmet} from "react-helmet-async";

const AboutUsPage: React.FC = () => {
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});

    const TermsTypography = styled(Typography)({
        userSelect: 'none',
        fontFamily: 'open-sans-regular',
        textAlign: 'left',
        marginBottom: 20,
        fontSize: 16,
    }) as typeof Typography;

    return (
        <div>
            <Helmet>
                <title>Despre noi</title>
                <meta name='description' content='About Strong logistic SRL.'/>
                <link rel="canonical" href="https://strong-logistic.ro/despre-noi" />
            </Helmet>

            <CardMedia
                sx={{
                    borderRadius: 0,
                    backgroundColor: 'transparent',
                }}
                component="img"
                height={isDesktopOrLaptop ? 500 : 250}
                image={contactUs}
                alt="banner"
            />

            <OverloadContainer
                maxWidth={false}
                sx={{
                    mt: isDesktopOrLaptop ? -15 : -5,
                    boxShadow: 10,
                    mb: 2,
                    pb: 2
                }}>

                <Typography
                    style={{
                        fontFamily: 'open-sans-bold',
                        fontSize: 25,
                        color: "#0e114c",
                        marginBottom: 20,
                        paddingTop: 30
                    }}>
                    DESPRE NOI
                </Typography>

                <TermsTypography>
                    Firma STRONG LOGISTIC SRL cu sediul in SELIMBAR Str. Regele Ferdinand 5 bl 2, ap 23, 557260, judetul
                    Sibiu, CUI 36489588,
                    a fost înființată in septembrie 2016 și are ca obiect principal de activitateTransporturi rutiere de
                    marfuri şi servicii de mutare (CAEN 4941).
                </TermsTypography>
                <TermsTypography>
                    Strong Logistic Srl este o firma de transporturi rutiere si expeditie care ofera servicii de
                    transport si livrare de bunuri prin intermediul unei flote moderne de vehicule. Acestia se
                    concentreaza pe livrarea eficienta, rapida si sigura a produselor clientilor lor, indiferent de
                    dimensiunea sau complexitatea acestora.
                </TermsTypography>
                <img
                    style={{marginBottom: 20, height: 100, display: 'flex', alignSelf: 'left', justifyContent: 'start'}}
                    src={logo} alt="banner"/>
                <TermsTypography>Număr înregistrare: J32/1143/2016</TermsTypography>
                <TermsTypography>CUI: 36489588</TermsTypography>
                <TermsTypography>Administrator: Silviu Fiera</TermsTypography>
                <TermsTypography>Data înfiinţării: 05-09-2016</TermsTypography>

            </OverloadContainer>
        </div>
    )
}

export default AboutUsPage;