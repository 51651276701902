import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'overlayscrollbars/overlayscrollbars.css';
import {OverlayScrollbars} from 'overlayscrollbars';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

OverlayScrollbars(document.body, {
    showNativeOverlaidScrollbars: false,
    scrollbars:{autoHide:'scroll',clickScroll:false, visibility:'hidden'},
    paddingAbsolute:true,
});


