import React from "react";
import {Box, Button, CardMedia, Grid, Typography} from "@mui/material";
import contactUs from "../../images/mainBanner.jpg";
import {OverloadContainer} from "../elements/StyledComponents";
import {useMediaQuery} from "react-responsive";
import styled from "@emotion/styled";
import logo from "../../images/home.jpg";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";

const HomeComponent: React.FC = () => {
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
    let navigate = useNavigate();

    function responsiveFont(fontSize: number) {
        return window.innerWidth * fontSize / 390;
    }

    const TermsTypography = styled(Typography)({
        userSelect: 'none',
        fontFamily: 'open-sans-regular',
        textAlign: 'left',
        marginBottom: 20,
        fontSize: 16,
    }) as typeof Typography;

    const ContactButton = styled(Button)({
        display: 'flex',
        justifyContent: 'center',
        mb: 2,
        mr: 2,
        color: 'white',
        backgroundColor: 'rgba(255,121,0,.8)',
        '&:hover': {
            backgroundColor: '#fff',
            color: 'rgba(255,121,0,.8)',
        }
    }) as typeof Button;

    return (
        <div>
            <Helmet>
                <title>Strong Logistic</title>
                <meta name='description' content='Strong logistic SRL Transport Ieftin Transport rapid Transport Expred.'/>
                <link rel="canonical" href="https://strong-logistic.ro/" />
            </Helmet>

            <CardMedia
                sx={{
                    userSelect: 'none',
                    backgroundColor: 'transparent',
                }}
                component="img"
                height={isDesktopOrLaptop ? 500 : 250}
                image={contactUs}
                alt="banner"
            />

            <Box
                sx={{
                    width: '100%',
                    mt: isDesktopOrLaptop ? -50 : -24,
                    borderRadius: 3,
                    backgroundColor: 'rgba(0,0,0,.4)',
                    position: 'absolute',
                    mb: isDesktopOrLaptop ? 10 : 3
                }}>

                <Typography
                    variant="h1"
                    style={{
                        userSelect: 'none',
                        textAlign: "center",
                        fontFamily: 'open-sans-regular',
                        fontSize: isDesktopOrLaptop ? 80 : responsiveFont(50),
                        color: 'whitesmoke',
                    }}>
                    Strong logistic
                </Typography>

                <Typography
                    variant="body2"
                    sx={{mt: isDesktopOrLaptop ? 1 : 0, justifyContent: 'flex-start'}}
                    style={{
                        userSelect: 'none',
                        textAlign: "center",
                        fontFamily: 'open-sans-regular',
                        fontSize: isDesktopOrLaptop ? 18 : 12,
                        color: 'white'
                    }}>
                    TRANSPORT RUTIER · TRANSPORT EXPRESS · VAN · CALITATE · ORIUNDE · ORICAND · STANDARDE INALTE · SINCE
                    2016
                </Typography>
            </Box>


            <OverloadContainer
                maxWidth={false}
                sx={{
                    mt: isDesktopOrLaptop ? -15 : -5,
                    boxShadow: 10,
                    mb: 2,
                    pb: 2,
                    pt: 2
                }}>

                <Box sx={{
                    display: 'flex',
                    mt: 1,
                    pb: 5,
                    backgroundColor: 'transparent',
                    width: '100%',
                    flexDirection: isDesktopOrLaptop ? 'row' : 'column',
                    justifyContent: 'space-around'
                }}>

                    <Grid container justifyContent='center' alignContent='center'
                          sx={{p: 1, flex: 1}}>
                        <img
                            style={{
                                userSelect: 'none',
                                marginBottom: 20,
                                height: isDesktopOrLaptop ? 400 : 200,
                                display: 'flex',
                                alignSelf: 'center',
                                justifyContent: 'center'
                            }}
                            src={logo} alt="banner"/>
                    </Grid>

                    <Grid container justifyContent='center' alignContent='center'
                          sx={{p: 1, flex: 1}}>
                        <Typography
                            style={{
                                userSelect: 'none',
                                fontFamily: 'open-sans-bold',
                                fontSize: 25,
                                color: "#0e114c",
                                marginBottom: 20,
                                paddingTop: 30
                            }}>
                            CATEVA CUVINTE DESPRE NOI
                        </Typography>

                        <TermsTypography>
                            Strong Logistic SRL este o firma de transporturi rutiere si expeditie care ofera servicii de
                            transport si livrare de bunuri prin intermediul unei flote moderne de vehicule. Acestia se
                            concentreaza pe livrarea eficienta, rapida si sigura a produselor clientilor lor, indiferent
                            de dimensiunea sau complexitatea acestora.
                        </TermsTypography>

                        <TermsTypography>
                            Cu o experienta solida in logistica si transport, echipa Strong Logistic SRL este dedicata
                            sa ofere servicii personalizate si adaptate nevoilor fiecarui client in parte. Ei se
                            straduiesc sa
                            ofere un serviciu excelent prin planificarea si monitorizarea atenta a fiecarei livrari,
                            precum si
                            prin
                            comunicarea deschisa si transparenta cu clientii.
                        </TermsTypography>

                        <TermsTypography>
                            In plus, Strong Logistic SRL se concentreaza pe respectarea standardelor de siguranta si
                            protejare a
                            mediului, asigurandu-se ca toate livrarile lor sunt efectuate cu respect fata de regulile si
                            regulamentele in vigoare.
                        </TermsTypography>

                        <TermsTypography>
                            In concluzie, Strong Logistic SRL este o alegere excelenta pentru orice companie care cauta
                            o firma de transport si expeditie de incredere, cu o experienta solida si cu un angajament
                            ferm fata
                            de serviciile de calitate si siguranta.
                        </TermsTypography>

                        <ContactButton onClick={() => navigate('/contact')}>CONTACT</ContactButton>

                    </Grid>

                </Box>

                <Box sx={{
                    borderColor: 'grey.500',
                    borderTop: 1,
                    mt: 1,
                    pt: 2,
                    pb: 5,

                    backgroundColor: 'transparent',
                    width: '100%',
                }}>
                    <Typography
                        style={{
                            userSelect: 'none',
                            fontFamily: 'open-sans-bold',
                            fontSize: 20,
                            color: "#0e114c",
                            marginBottom: 20,
                            paddingTop: 30
                        }}>
                        Oferim urmatoarele servicii:
                    </Typography>

                    <TermsTypography style={{textAlign: 'center'}}>
                        - Transport rapid de marfa in regim grupaj sau exclusiv din toata Europa catre Romania sau
                        Grecia si retur.
                    </TermsTypography>
                    <TermsTypography style={{textAlign: 'center'}}>
                        - Transport rutier national si international
                    </TermsTypography>
                    <TermsTypography style={{textAlign: 'center'}}>
                        - Livrari rapide
                    </TermsTypography>
                    <TermsTypography style={{textAlign: 'center'}}>
                        - Servicii de logistica si stocare
                    </TermsTypography>
                    <TermsTypography style={{textAlign: 'center'}}>
                        - Manevrare si descarcare marfuri
                    </TermsTypography>
                    <TermsTypography style={{textAlign: 'center'}}>
                        - Asigurare marfuri
                    </TermsTypography>
                    <TermsTypography style={{textAlign: 'center'}}>
                        - Consultanta in domeniul transporturilor
                    </TermsTypography>

                </Box>

            </OverloadContainer>
        </div>
    )
}

export default HomeComponent;