import React from "react";
import {AppBar, Box, CardMedia, Container, Grid, Typography, useMediaQuery} from "@mui/material";
import contactUs from "../../images/footer.png";
import logo from "../../images/logo-transparent.png";
import PhoneIcon from "@mui/icons-material/Phone";
import styled from "@emotion/styled";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Link from "@mui/material/Link";
import {useNavigate} from "react-router-dom";

const FooterComponent: React.FC = () => {
    const isDesktopOrLaptop = useMediaQuery('(min-width:1224px)');
    let navigate = useNavigate();

    const FooterBox = styled(Box)({
        backgroundColor: 'rgba(250,255,255,.05)',
        elevation: 30,
        height: isDesktopOrLaptop ? 200 : 200,
        width: 400,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'flex-start',
    }) as typeof Box;

    const BoxLink = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'flex-start',
        margin: 3
    }) as typeof Box;

    return (
        <>
            <AppBar position="relative" sx={{userSelect: 'none',top: 'auto', bottom: 0, backgroundColor: 'transparent'}}>
                <Container disableGutters
                           maxWidth={false} sx={{backgroundColor: 'transparent'}}>
                    <CardMedia
                        sx={{backgroundColor: 'transparent'}}
                        component="img"
                        height={isDesktopOrLaptop ? 270 : 690}
                        image={contactUs}
                        alt="banner"
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            mt: isDesktopOrLaptop ? -32 : -85,
                            mb: 0,
                            backgroundColor: 'transparent',
                            position: 'absolute',
                            width: '100%',
                            flexDirection: isDesktopOrLaptop ? 'row' : 'column',
                            justifyContent: 'space-around'
                        }}>
                        <Grid container justifyContent={isDesktopOrLaptop ? 'flex-end' : 'center'} alignContent='center'
                              sx={{p: 1, flex: 2}}>
                            <FooterBox sx={{display: 'flex', boxShadow: 10}}>
                                <img style={{userSelect: 'none',height: 100, alignSelf: 'center'}} src={logo} alt="banner"/>
                            </FooterBox>

                        </Grid>

                        <Grid container justifyContent="space-around" alignContent='center' sx={{p: 1, flex: 1.5}}>
                            <FooterBox sx={{boxShadow: 10}}>
                                <Box sx={{flexDirection: 'column', display: 'flex', pt: .5, pl: 2, pr: 2}}>
                                    <Typography
                                        style={{userSelect: 'none', fontSize: 18, color: 'white', paddingLeft: 10}}>
                                        CONTACT
                                    </Typography>

                                    <BoxLink>
                                        <AccessTimeIcon/>
                                        <Typography
                                            style={{userSelect: 'none', fontSize: 16, color: 'white', paddingLeft: 10}}>
                                            Luni - Vineri 9-18
                                        </Typography>
                                    </BoxLink>

                                    <a href="tel:0747052042" style={{textDecoration: 'none'}}>
                                        <BoxLink>
                                            <PhoneIcon sx={{color: 'white'}}/>
                                            <Typography style={{
                                                userSelect: 'none',
                                                fontSize: 16,
                                                color: 'white',
                                                paddingLeft: 10
                                            }}>
                                                +40747052042
                                            </Typography>
                                        </BoxLink>
                                    </a>

                                    <a href="mailto:office@strong-logistic.ro" style={{textDecoration: 'none'}}>
                                        <BoxLink>
                                            <EmailIcon sx={{color: 'white'}}/>
                                            <Typography style={{
                                                userSelect: 'none',
                                                fontSize: 16,
                                                color: 'white',
                                                paddingLeft: 10
                                            }}>
                                                office@strong-logistic.ro
                                            </Typography>
                                        </BoxLink>
                                    </a>

                                    <BoxLink>
                                        <LocationOnIcon/>
                                        <Typography
                                            style={{userSelect: 'none', fontSize: 16, color: 'white', paddingLeft: 10}}>
                                            Str. Regele Ferdinand 5 bl 2, ap 23
                                        </Typography>

                                    </BoxLink>

                                    <Typography style={{
                                        userSelect: 'none',
                                        fontSize: 16,
                                        color: 'white',
                                        alignSelf: 'flex-start',
                                        paddingLeft: 38
                                    }}>
                                        RO - 557260 Selimber, Sibiu
                                    </Typography>
                                </Box>
                            </FooterBox>
                        </Grid>


                        <Grid container justifyContent={isDesktopOrLaptop ? 'flex-start' : 'center'}
                              alignContent='center' sx={{p: 1, flex: 2}}>
                            <FooterBox>
                                <Box sx={{flexDirection: 'column', display: 'flex', pt: .5, pl: 2, pr: 2}}>
                                    <Typography
                                        style={{userSelect: 'none', fontSize: 18, color: 'white', paddingLeft: 10}}>
                                        UTILE
                                    </Typography>

                                    <a href="https://anpc.ro/" style={{userSelect: 'none', textDecoration: 'none'}}>
                                        <BoxLink>
                                            <Typography style={{fontSize: 16, color: 'white', paddingLeft: 10}}>
                                                ANPC
                                            </Typography>
                                        </BoxLink>
                                    </a>


                                    <BoxLink>
                                        <Link
                                            style={{textDecoration: 'none'}}
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                navigate('/termeni-conditii');
                                            }}
                                        >
                                            <Typography style={{fontSize: 16, color: 'white', paddingLeft: 10}}>
                                                Termeni si conditii
                                            </Typography>
                                        </Link>


                                    </BoxLink>

                                    <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
                                       style={{textDecoration: 'none'}}>
                                        <BoxLink>
                                            <Typography style={{
                                                userSelect: 'none',
                                                fontSize: 16,
                                                color: 'white',
                                                paddingLeft: 10
                                            }}>
                                                SOL/SAL
                                            </Typography>
                                        </BoxLink>
                                    </a>
                                </Box>


                            </FooterBox>
                        </Grid>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        mt: -4,
                        mb: 0,
                        backgroundColor: 'transparent',
                        position: 'absolute',
                        width: '100%',
                        flexDirection: isDesktopOrLaptop ? 'row' : 'column',
                        justifyContent: 'space-around'
                    }}>
                        <Typography style={{userSelect: 'none', fontSize: 16, color: 'white', paddingLeft: 10}}>
                            Copyright © 2023. Toate drepturile rezervate.
                        </Typography>
                    </Box>


                </Container>

            </AppBar>


        </>
    )
}

export default FooterComponent;